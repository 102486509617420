import { useEffect, useRef, useState } from 'react';
import type React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useSelectedComment from '@/comments/hooks/useSelectedComment';
import CommentsList from '@/comments/components/CommentsList';
import useComments from '@/comments/hooks/useComments';
import withComments from '@/comments/components/withComments';
import useSidebars from '@/sidebars/hooks/useSidebars';
import NewComment from '@/comments/components/NewComment';
import useComment from '@/comments/hooks/useComment';
import { usePrevious } from '@/storychief/hooks';
import { getEditorComments, getTitleEditorComments } from '@/editor/selectors';
import EditorCommentsHeader from '@/editor/components/EditorCommentsHeader';
import StoryChief from '@/storychief';

type Position = {
  transform?: string;
  left?: number | string;
  right?: number;
  bottom?: number;
  top?: number;
};

type EditorComment = {
  editorText: [string];
  key: string;
  ranges: [];
};

type EditorCommentsProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  modelType:
    | 'Story'
    | 'Ebook'
    | 'Podcast'
    | 'VideoProject'
    | 'WebsiteContent'
    | 'Webinar'
    | 'Ebook'
    | 'Newsletter'; // Used in withComments HOC.
  // eslint-disable-next-line react/no-unused-prop-types
  modelId: string; // Used in withComments HOC.
  editorComments: EditorComment[];
  onTempEditorCommentClean: () => void;
  positionMode?: 'editor' | 'comment';
};

function EditorComments({
  editorComments,
  onTempEditorCommentClean,
  positionMode = 'editor',
}: EditorCommentsProps): React.JSX.Element {
  // Hooks
  const editorCommentsNode = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { comments } = useComments();
  const titleEditorComments = useSelector(getTitleEditorComments);
  const contentEditorComments = useSelector(getEditorComments);
  const allActiveEditorComments = [...titleEditorComments, ...contentEditorComments].filter(
    isActiveEditorComment,
  );
  const { selectedCommentEditorKey, setSelectedCommentEditorKey } = useSelectedComment(comments);
  const [selectedComment, setSelectedComment] = useState(getSelectedComment());
  const { resolveComment } = useComment({ comment: selectedComment });
  const sidebars = useSidebars();
  const isCommentsSidebarOpen = sidebars.data.some((s) => s.id === 'CommentsSidebar');

  // States
  const [position, setPosition] = useState<Position | null>(null);
  const prevPosition = usePrevious(position);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const isPrevIsOverflowing = usePrevious(isOverflowing);

  // Variables
  const className = classNames('sc-editor-comments', {
    'sc-editor-comments--overflowing': isOverflowing,
    'sc-editor-comments--animate': prevPosition !== null,
    'sc-editor-comments--hidden': position === null, // Avoid content jump if the next render after the position is calculated is delayed.
  });
  const tempEditorComment = editorComments.find((comment) =>
    comments.every((c) => c.editor_key !== comment.key),
  );
  const isCurrentUserGuest = !StoryChief?.user?.id;

  // Effects
  useEffect(() => {
    setSelectedComment(getSelectedComment());
  }, [selectedCommentEditorKey, editorCommentsNode.current, comments]);

  useEffect(() => {
    if (editorCommentsNode.current) {
      const editorContainerNode = editorCommentsNode.current.closest('[data-editor-container]')!;

      const resizeObserver = new ResizeObserver(() => {
        if (editorCommentsNode.current && !isMobile()) {
          repositionEditor();
          setPosition(getPosition());
        } else {
          resetEditorPosition();
          setPosition(getPosition());
        }
      });
      resizeObserver.observe(editorContainerNode);

      return () => {
        resizeObserver.unobserve(editorContainerNode);
        resetEditorPosition();
        setPosition(getPosition());
      };
    }

    return () => {};
  }, [editorCommentsNode.current, selectedComment, tempEditorComment]);

  useEffect(() => {
    if (bodyRef.current) {
      const el = bodyRef.current;

      const resizeObserver = new ResizeObserver(checkOverflow);
      resizeObserver.observe(el);

      return () => {
        if (el) {
          resizeObserver.unobserve(el);
        }
      };
    }

    return () => {};
  }, [bodyRef.current]);

  useEffect(() => {
    if (!isPrevIsOverflowing && isOverflowing) {
      setTimeout(() => {
        if (bodyRef.current) {
          bodyRef.current.scrollTop = 0;
        }
      }, 1);
    }
  }, [isOverflowing]);

  // Functions
  function isActiveEditorComment(editorComment: EditorComment) {
    return comments.some((c) => c.editor_key === editorComment.key && !c.resolved && !c.deleted_at);
  }

  function resetEditorPosition() {
    const storyContainer = document.querySelector('.story-edit-container') as HTMLElement;

    if (storyContainer && positionMode === 'editor') {
      storyContainer.style.paddingRight = '0';
    }
  }

  function repositionEditor() {
    const storyContainer = document.querySelector('.story-edit-container') as HTMLElement;

    if (storyContainer && positionMode === 'editor') {
      const editor = storyContainer.querySelector('.DraftEditor-root') as HTMLElement;
      const editorContainer = editor.closest('[data-editor-container]') as HTMLElement;
      const editorWidth =
        editor.getBoundingClientRect().width + parseInt(storyContainer.style.paddingRight, 10);
      const availableWidth = (editorContainer.getBoundingClientRect().width - editorWidth) / 2;
      const editorCommentsWidth = editorCommentsNode.current.clientWidth;
      const editorCommentsScreenPadding = 20;

      if (availableWidth < editorCommentsWidth + editorCommentsScreenPadding) {
        storyContainer.style.paddingRight = `${
          editorCommentsWidth + editorCommentsScreenPadding
        }px`;
      } else {
        storyContainer.style.paddingRight = '0';
      }
    }
  }

  function isMobile() {
    return window.innerWidth < 768;
  }

  function getSelectedComment() {
    // Check if the selected comment is inside the editor
    if (editorComments.every((c) => c.key !== selectedCommentEditorKey)) {
      return null;
    }

    return selectedCommentEditorKey
      ? comments.find((c) => c.editor_key === selectedCommentEditorKey)
      : null;
  }

  function handleCommentNavigation(dir: 'next' | 'back') {
    const index = allActiveEditorComments.findIndex((c) => c.key === selectedCommentEditorKey);
    let newIndex = dir === 'next' ? index + 1 : index - 1;

    if (newIndex < 0) {
      newIndex = allActiveEditorComments.length - 1;
    } else if (newIndex > allActiveEditorComments.length - 1) {
      newIndex = 0;
    }

    setSelectedCommentEditorKey(allActiveEditorComments[newIndex].key);
  }

  function getPosition(): Position | null {
    if (isMobile()) {
      return {
        bottom: 0,
        left: 0,
      };
    }

    const editorContainerNode = editorCommentsNode?.current?.closest('[data-editor-container]');
    const editorInnerContainerNode = editorCommentsNode?.current?.closest('.editor-container');
    const selectedCommentNodes = editorInnerContainerNode?.querySelectorAll('.comment-selected');
    const editorNode = editorInnerContainerNode?.querySelector('.DraftEditor-root');

    if (
      !editorCommentsNode?.current ||
      !editorInnerContainerNode ||
      !selectedCommentNodes ||
      selectedCommentNodes.length === 0 ||
      !editorNode ||
      !editorContainerNode
    ) {
      return null;
    }

    const editorNodeRect = editorInnerContainerNode.getBoundingClientRect();

    if (positionMode === 'comment') {
      const editorContainerNodeRect = editorContainerNode.getBoundingClientRect();
      const selectedCommentNodeRect =
        selectedCommentNodes[selectedCommentNodes.length - 1].getBoundingClientRect();
      const PADDING = 100;
      const availableSpace = (editorContainerNodeRect.width - editorNodeRect.width - PADDING) / 2;

      if (availableSpace < editorCommentsNode.current.clientWidth) {
        return {
          transform: `translateY(${
            selectedCommentNodeRect.top - editorNodeRect.top + selectedCommentNodeRect.height
          }px)`,
          left: 'auto',
          right: -60,
          top: 0,
        };
      }
    }

    const selectedCommentNodeRect = selectedCommentNodes[0].getBoundingClientRect();

    return {
      transform: `translateY(${selectedCommentNodeRect.top - editorNodeRect.top}px)`,
      top: 0,
    };
  }

  function handleOnCommentCancel() {
    setSelectedComment(null);
    setSelectedCommentEditorKey(null);
  }

  async function handleResolveComment() {
    if (isCurrentUserGuest) return;

    await resolveComment({
      variables: {
        input: {
          id: selectedComment.id,
          resolved: !selectedComment.resolved,
        },
      },
    });

    setSelectedComment(null);
    setSelectedCommentEditorKey(null);
  }

  function checkOverflow() {
    const el = bodyRef.current;

    if (el) {
      setIsOverflowing(el.scrollHeight > el.clientHeight);
    }
  }

  function handleOnNewCommentCancel() {
    handleOnCommentCancel();
    onTempEditorCommentClean();
  }

  // Render
  if ((!selectedComment && !tempEditorComment) || isCommentsSidebarOpen) {
    return null;
  }

  if (tempEditorComment) {
    return (
      <div ref={editorCommentsNode} className={className} style={position || undefined}>
        <div className="sc-editor-comments__footer">
          <NewComment
            isAnimated
            isAutofocus
            variant="editor"
            editorKey={tempEditorComment.key}
            editorRanges={tempEditorComment.ranges}
            editorText={tempEditorComment.editorText}
            onCancel={handleOnNewCommentCancel}
          />
        </div>
      </div>
    );
  }

  return (
    <div ref={editorCommentsNode} className={className} style={position || undefined}>
      <EditorCommentsHeader
        isNavDisabled={allActiveEditorComments.length === 1}
        onResolve={handleResolveComment}
        onCancel={handleOnCommentCancel}
        onNavigate={handleCommentNavigation}
      />

      <div className="sc-editor-comments__body" ref={bodyRef}>
        <CommentsList
          isCommentSelectable={false}
          isCommentResolvable={false}
          isCommentThreadToggleable={false}
          isCommentReplyable={false}
          initialCommentsToShow={3}
          comments={[selectedComment]}
          onCommentSelected={() => {}}
          onCommentCancel={() => {}}
          variant="editor"
        />
      </div>

      <div className="sc-editor-comments__footer">
        <NewComment isAnimated isResettable variant="editor-reply" parentId={selectedComment.id} />
      </div>
    </div>
  );
}

export default withComments(EditorComments);
