import { Icon } from '@iconify-icon/react/dist/iconify.mjs';
import classNames from 'classnames';
import { useEffect, useState, type JSX } from 'react';
import Button from '@/storychief/components/Button';
import useEditorCollaboration from '@/editor/hooks/useEditorCollaboration';

function EditorScBlockCollaborationIndicator(): JSX.Element {
  const { blockTarget } = useEditorCollaboration();

  const editorCollaborationDomEl = document.querySelector('.editor-block-collaboration');
  const [secondaryEditorAnchorPosition, setSecondaryEditorAnchorPosition] = useState(null);

  useEffect(() => {
    function handleScroll() {
      if (editorCollaborationDomEl) {
        const { top, bottom } = editorCollaborationDomEl.getBoundingClientRect();

        if (top > window.innerHeight) {
          setSecondaryEditorAnchorPosition('bottom');
        } else if (bottom < 0) {
          setSecondaryEditorAnchorPosition('top');
        } else {
          setSecondaryEditorAnchorPosition(null);
        }
      }
    }

    if (blockTarget && editorCollaborationDomEl) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [blockTarget, editorCollaborationDomEl]);

  function scrollIntoCollaborationEditor() {
    const offset = 150;

    if (editorCollaborationDomEl) {
      const elementPosition =
        editorCollaborationDomEl.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
  }

  const _classNames = classNames('fixed z-[0] opacity-0 transition-all', {
    '!top-[100px]': secondaryEditorAnchorPosition === 'top',
    '!z-[999] opacity-100': !!secondaryEditorAnchorPosition,
    'bottom-[20px]': secondaryEditorAnchorPosition === 'bottom',
  });

  return (
    <div className={_classNames}>
      <Button variant="primary" onClick={scrollIntoCollaborationEditor} size="sm">
        <Icon
          icon="fa:arrow-up"
          inline
          className="mr-2"
          rotate={secondaryEditorAnchorPosition === 'bottom' ? '180deg' : undefined}
        />
        Continue editing
      </Button>
    </div>
  );
}

export default EditorScBlockCollaborationIndicator;
