import { Icon } from '@iconify-icon/react';
import { useState, type JSX } from 'react';
import { LOCK_EVENTS } from '@/locker/constants';
import Alert from '@/storychief/components/Alert';
import { useEmitEvent, useEmitEventEffect } from '@/storychief/hooks';
import Button from '@/storychief/components/Button';
import useAccount from '@/account/hooks/useAccount';

function EditorLockBanner({ show, userWithLock, isRequestingLock }): JSX.Element {
  // Hooks

  const takeLock = useEmitEvent(LOCK_EVENTS.takeLock);
  const requestLock = useEmitEvent(LOCK_EVENTS.requestLock);
  const account = useAccount();

  // States
  const [allowForceTakeLock, setAllowForceTakeLock] = useState(false);

  // Effects
  useEmitEventEffect((value) => {
    setAllowForceTakeLock(value);
  }, LOCK_EVENTS.allowForceTakeLock);

  // Functions
  function getAlertMainEditorText() {
    return (
      <div className="flex flex-col gap-1 text-sm">
        <div className="flex justify-between">
          <h2 className="m-0 p-0 text-lg">
            <em className="icon-lock" />
            <span className="capitalize">{userWithLock}</span> is the main editor right now.
          </h2>
        </div>

        <span>
          Hover and click on <Icon icon="fa:pencil" inline /> <b>edit</b> on a paragraph to begin
          editing. Alternatively request full access below.
        </span>
      </div>
    );
  }

  function getAlertMainEditorButtons() {
    return (
      <div className="flex gap-4">
        <Button
          className="m-0 flex items-center p-0"
          variant="link"
          size="xs"
          onClick={requestLock}
          disabled={isRequestingLock}
          data-testid="editor-lock-banner-request-button"
        >
          {isRequestingLock ? (
            <span className="gutter-left-1" data-testid="editor-lock-banner-request-loading">
              <span className="animated-spin d-inline-block">
                <span className="icon-spin1" />
              </span>
              Requesting...
            </span>
          ) : (
            'Request access'
          )}
        </Button>
        {allowForceTakeLock && (
          <Button
            className="m-0 p-0"
            variant="link"
            size="xs"
            onClick={takeLock}
            data-testid="editor-lock-banner-force-take-button"
          >
            Force take
          </Button>
        )}
      </div>
    );
  }

  if (!show || !account) {
    return null;
  }

  return (
    <div className="editor-container mb-8 mt-4" data-testid="editor-lock-banner">
      <Alert variant="info" className="border-0">
        <div className="flex flex-col gap-4">
          {getAlertMainEditorText()}
          {getAlertMainEditorButtons()}
        </div>
      </Alert>
    </div>
  );
}

export default EditorLockBanner;
